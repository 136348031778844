@media (min-width:768px) {
    .cancer-diagnostics-row .card-3 h3 {
        min-height: 44px;
    }
}

@media (min-width:992px) {
    .cancer-diagnostics-row .card-3 h3 {
        min-height: 48px;
    }
}

@media (min-width:1200px) {
    .cancer-diagnostics-row .card-3 h3 {
        min-height: unset;
    }
}

@media (min-width:1600px) {
    .cancer-diagnostics-row {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width:1800px) {
    .cancer-diagnostics-row {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width:767px) {
    .diagnostics-sub-heading strong {
        font-size: 24px;
    }

    .diagnostics-sub-heading sup {
        font-size: 20px;
    }
}