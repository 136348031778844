.cancer-consultation-sec {
    background-color: #F6F3F0;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.cancer-consultation-inner img {
    width: 100%;
    aspect-ratio: 1.3 / 1;
    object-fit: cover;
    border-radius: 25px 25px 0 0;
}

.cancer-consultation-inner .heading-5 {
    color: #3d3d3d;
}

@media (min-width:992px) {
    .consultation-heading-width {
        width: 65%;
    }
}

@media (min-width:1200px) {
    .consultation-heading-width {
        width: 55%;
    }
}

@media (min-width:1600px) {
    .consultation-heading-width {
        width: 45%;
    }
}

@media (min-width:1800px) {
    .consultation-heading-width {
        width: 45%;
    }
}

@media (max-width:991px) {
    .steps-container {
        flex-direction: column;
        gap: 24px;
    }

    .steps-container .steps-arrow {
        transform: rotate(90deg);
    }
}

@media (max-width:767px) {
    .cancer-consultation-sec {
        background-color: #fff;
    }

    .cancer-consultation-sec .my-container {
        padding-top: 0;
    }

    .consultation-sub-heading strong {
        font-size: 24px;
    }

    .consultation-sub-heading sup {
        font-size: 20px;
    }

    .cancer-consultation-inner img {
        aspect-ratio: unset;
        height: 300px;
    }

    .cancer-consultation-inner .heading-5 {
        font-size: 20px;
    }
}