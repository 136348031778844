.partners-heading {
    text-align: center;
}

.partners-container {
    height: 250px;
    padding: 0 6rem;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.partner1,
.partner2,
.partner3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner1 img,
.partner2 img,
.partner3 img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .partners-container {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 0 2rem;
    }

    .partner1,
    .partner2,
    .partner3 {
        margin: 1rem 0;
        padding: 2rem 0;
    }
}

@media (max-width: 480px) {
    .partners-container {
        padding: 0 1rem;
    }

    .partner1,
    .partner2,
    .partner3 {
        padding: 1rem 0;
    }
}
