.acticle-banner {
  overflow: hidden;
}

.acticle-banner .acticle-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .acticle-banner {
    background-color: var(--pitch);
  }
}

@media (min-width: 992px) {
  .acticle-banner .row {
    position: relative;
    min-height: 450px;
  }

  .acticle-banner .acticle-banner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .acticle-banner .acticle-banner-container > div {
    width: calc(50% - 40px);
  }
}

@media (min-width: 1200px) {
  .acticle-banner-heading-width {
    width: 95%;
  }
}

@media (min-width: 1400px) {
  .acticle-banner-heading-width {
    width: 90%;
  }

  .acticle-banner-p-width {
    width: 90%;
  }
}

@media (min-width: 1600px) {
  .acticle-banner .row {
    position: relative;
    min-height: 550px;
  }

  .acticle-banner-p-width {
    width: 75%;
  }
}

@media (min-width: 1800px) {
  .acticle-banner-heading-width {
    width: 80%;
  }

  .acticle-banner-p-width {
    width: 70%;
  }
}

@media (max-width: 1399px) {
  .acticle-banner .acticle-banner-img {
    object-position: 90%;
  }
}

@media (max-width: 1199px) {
  .acticle-banner .acticle-banner-img {
    object-position: 80%;
  }
}

@media (max-width: 991px) {
  .acticle-banner .row .col-lg-6:first-child {
    max-height: 60vh;
    aspect-ratio: 1.5 / 1;
  }
}

@media (max-width: 767px) {
  .acticle-banner .acticle-banner-img {
    object-position: 88%;
  }

  .acticle-banner-container {
    background-color: var(--pitch);
    padding: 24px;
    margin-top: -40px;
  }

  .acticle-banner h3 {
    font-size: 22px;
  }

  .acticle-banner p {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .acticle-banner-container {
    width: calc(100% - 24px);
    margin-left: 12px;
    margin-right: 12px;
  }
}
