.structured-framework-card .card-1 p:first-child {
  font-weight: 700;
}

@media (min-width: 1400px) {
  .structured-framework-card .card-1 div:nth-child(1) {
    min-height: 87px;
  }
  .structured-framework-card .card-1 div:nth-child(2) p:first-child {
    min-height: 60px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .structured-framework-card {
    width: 80%;
  }
  .structured-framework-card .row > * {
    width: 50%;
  }
}
