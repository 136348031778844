.info1 {
  margin-top: 6rem;
  background-color: rgba(248, 248, 248, 1);
  padding-top: 2rem;
}

.info2 {
  background-color: white;
}

.info1,
.info2 {
  padding: 1.5rem 5rem;
}

.info1-heading,
.info2-heading {
  padding: 1rem 0;
  font-weight: 600;
}

.info1-text,
.info2-text {
  font-size: 1.05rem;
  font-weight: 450;
  border: none;
  font-family: "Playfair Display";
  line-height: 1.9rem;
}

.info3 {
  margin: 3rem 22vw;
  padding: 2.5rem;
  border: 1.5px solid black;
  border-radius: 1rem;
}

.info3-heading {
  font-size: 1.2rem;
  font-weight: 550;
  line-height: 2rem;
}

.bullet-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.bullet-container p {
  width: 95%;
  font-size: 1rem;
}

.bullet-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: translate(50%, 50%);
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .info1,
  .info2 {
    padding: 1rem 2rem;
  }

  .info3 {
    margin: 2rem 10vw;
    padding: 2rem;
  }

  .bullet-container {
    gap: 0.5rem;
  }

  .info1-text,
  .info2-text,
  .info3-heading,
  .bullet-container p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .info1,
  .info2 {
    padding: 0.5rem 1rem;
    width: 90%;
  }
.info1-heading,.info2-heading{
  text-align: center;
}
  .info3 {
    margin: 1.5rem 0.5rem;
    padding: 1rem;
    width: 85%;
  }

  .info1-text,
  .info2-text,
  .info3-heading,
  .bullet-container p {
    font-size: 0.9rem;
  }
  .bullet-container p {
    padding: 0rem 0.2rem;
  }
  .bullet-container{
    margin-top: 0;
    margin-bottom: 0;
  }
}
