.our-solutions-sec {
  background-color: #f8f8f8;
}

.our-solutions-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-solutions-card img {
  width: 90%;
  margin-top: -40px;
}

@media (min-width: 992px) {
  .our-solutions-p-width {
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .our-solutions-p-width {
    width: 75%;
  }
}

@media (min-width: 1600px) {
  .our-solutions-p-width {
    width: 63%;
  }
}

@media (min-width: 1800px) {
  .our-solutions-p-width {
    width: 57%;
  }
}

@media (max-width: 1799px) {
  .our-solutions-card h4 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .our-solutions-card img {
    width: 100%;
  }
}
