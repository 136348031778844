.send-query {
  position: relative;
  padding: 2rem;
  margin-top: 1rem;
}

.query-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
  width: 100%;
}

.query-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem;
  border-radius: 8px;
}

.query-card img {
  height: 5vw;
  width: 5vw;
  margin-bottom: 1rem;
}

.query-heading {
  font-size: 1.2rem;
  font-weight: 550;
}

.query-desc {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.straight-line {
  background-color: black;
  width: 1px;
  height: 40vh;
  display: block;
}

.query-stats {
  width: 50%;
  font-size: 1.2rem;
  padding: 2rem 1rem;
  text-align: center;
}
.query-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.query-button {
  color: black;
  background-color: rgba(191, 221, 203, 1);
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Playfair Display';
  width: 400px;
  height: 3rem;
  border-radius: 30px;
  border: 1px solid black;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 2rem;
}

/* Media Queries for Tablets */
@media screen and (max-width: 768px) {
  .query-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .query-card {
    width: 45%;
    margin-bottom: 2rem;
  }

  .query-card img {
    height: 8vw;
    width: 8vw;
  }

  .query-heading {
    font-size: 1.2rem;
  }

  .query-desc {
    font-size: 0.9rem;
    margin-left: 0;
    width: 100%;
  }

  .query-stats {
    font-size: 1rem;
    padding: 1rem;
  }

  .query-button {
    width: 50vw;
    font-size: 1rem;
  }

  .straight-line {
    display: none; 
  }
}

/* Media Queries for Mobile Phones */
@media screen and (max-width: 480px) {
  .query-container {
    flex-direction: column;
  }

  .query-card {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0rem 2rem;
  }

  .query-card img {
    height: 10vw;
    width: 10vw;
  }

  .query-heading {
    font-size: 1.5rem;
  }

  .query-desc {
    font-size: 1.2rem;
    margin-top: 0.1rem;
    padding-right: 2.5rem;
  }

  .query-stats {
    font-size: 1.1rem;
    font-weight: 450;
    padding-left: 1rem;
    width: 90%;
    border-left: 2px black solid;
  }

  .query-button {
    width: 50vw;
    font-size: 1.2rem;
    margin: 0 23%;
    font-weight: 450;
    padding: 0.5rem 0rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  }
  .query-button img{
    width: 2rem;
  }

  .straight-line {
    display: none;
  }
}
