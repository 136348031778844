.edit-profile-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 25px;
  cursor: pointer;
}

.img-circle {
  width: 100px;
  aspect-ratio: 1 / 1;
  background-color: #f8f8f8;
  border: 1px solid rgba(187, 187, 187, 40%);
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 13px;
}
.user-profile-img {
  width: 100px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border: 1px solid rgba(187, 187, 187, 40%);
  border-radius: 50%;
}

.add-img {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 9px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px #0000001a;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.add-img img {
  width: 18px;
}
