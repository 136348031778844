@media (min-width:1600px) {
    .what-we-do-card .full-width-card1 .text-width {
        width: 85%;
    }
}

@media (min-width:1800px) {
    .what-we-do-card .full-width-card1 .text-width {
        width: 77%;
    }
}