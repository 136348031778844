@media (min-width:992px) {
    .employers-banner-title-width {
        width: 80%;
    }
}

@media (min-width:1200px) {
    .employers-banner-title-width {
        width: 70%;
    }
}

@media (min-width:1600px) {
    .employers-banner-title-width {
        width: 60%;
    }
}

@media (min-width:1800px) {
    .employers-banner-title-width {
        width: 55%;
    }
}

@media (max-width:767px) {
    .employers-banner .main-banner {
        background-position: 80% !important;
    }

    .employers-banner .title-container-sm div {
        background-color: #fff;
        margin-top: -25px;
    }

    .employers-pitch-board p {
        font-size: 16px;
    }
}

@media (max-width:575px) {
    .employers-banner .main-banner {
        background-position: 58% !important;
    }
}