@media (min-width:992px) {
    .solution-banner-title-width {
        width: 58%;
    }
}

@media (min-width:1200px) {
    .solution-banner-title-width {
        width: 45%;
    }
}

@media (min-width:1400px) {
    .solution-banner-title-width {
        width: 50%;
    }
}

@media (min-width:1600px) {
    .solution-banner-title-width {
        width: 40%;
    }
}

@media (min-width:1800px) {
    .solution-banner-title-width {
        width: 38%;
    }
}

@media (max-width:767px) {
    .solutions-banner .main-banner {
        background-position: 60% !important;
    }
}