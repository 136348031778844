@media (min-width: 992px) {
  .business-heading-width {
    width: 80%;
  }

  .solutions-business-card .card-1 div:nth-child(1) {
    min-height: 101px;
  }
}

@media (min-width: 1200px) {
  .business-heading-width {
    width: 65%;
  }

  .solutions-business-card .card-1 div:nth-child(1) {
    min-height: 87px;
  }

  .solutions-business-card .card-1 div:nth-child(2) p:first-child {
    min-height: 60px;
  }
}

@media (min-width: 1400px) {
  .business-heading-width {
    width: 70%;
  }
}

@media (min-width: 1600px) {
  .business-heading-width {
    width: 60%;
  }
}

@media (min-width: 1800px) {
  .business-heading-width {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .solutions-business-card .card-1 h3 {
    font-size: 22px;
  }

  .solutions-business-card .card-1 p {
    font-size: 16px;
  }
}
