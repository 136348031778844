.card-1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px 0;
    padding: 30px 25px;
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.card-2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 25px 25px 25px;
    border: 1px solid #D2D2D2;
    border-bottom: 7px solid var(--pink);
    border-radius: 4px;
    margin-top: 60px;
}

.card-2 img {
    width: 170px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -60px;
}

.card-3 {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-3>img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 48px;
}

.card-3>div {
    padding: 30px 25px;
    border-bottom: 5px solid var(--pink);
    background-color: #f8f8f8;
    border-radius: 4px;
    flex-grow: 1;
}

.card-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-4>img {
    width: 90%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 50px;
}

.small-card1 {
    height: 260px;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    border: 1px solid #B9B9B9;
    border-radius: 8px;
}

.small-card2 {
    height: 260px;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    background: #F8F8F8;
    border: 1px solid #D2D2D2;
    border-bottom: 6px solid #000;
    border-radius: 6px;
}

.small-card1 h3,
.small-card2 h3 {
    font-size: 50px;
}

.small-card1 h4,
.small-card2 h4 {
    font-size: 24px;
}

.full-width-card1,
.full-width-card2 {
    border-radius: 8px;
    overflow: hidden;
}

.full-width-card1 img,
.full-width-card3 .full-width-card-3-img {
    width: 100%;
    aspect-ratio: 2 / 1;
    object-fit: cover;
    border-radius: 8px;
}

.full-width-card2 .full-width-card-2-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.full-width-card4 {
    border-bottom: 6px solid #000;
    border-radius: 6px;
}

@media (min-width:992px) {
    .full-width-card2 .full-width-card-2-img {
        border-radius: 0 8px 8px 0;
    }
}

@media (min-width:1200px) {
    .full-width-card2 .row {
        min-height: 400px;
    }
}

@media (min-width:1400px) {
    .card-1 {
        gap: 40px 0;
        padding: 40px 30px;
    }

    .full-width-card2 .row {
        min-height: 500px;
    }
}

@media (min-width:1600px) {
    .card-1 {
        gap: 50px 0;
    }
}

@media (max-width:1799px) {

    .small-card1,
    .small-card2 {
        height: 210px;
    }
}

@media (max-width:1399px) {

    .small-card1,
    .small-card2 {
        height: 170px;
    }

    .small-card1 h3,
    .small-card2 h3 {
        font-size: 32px;
    }

    .small-card1 h4,
    .small-card2 h4 {
        font-size: 16px;
    }

    .card-2 img {
        width: 145px;
    }
}

@media (max-width:1199px) {

    .small-card1,
    .small-card2 {
        height: 145px;
    }

    .card-3>img {
        margin-bottom: 24px;
    }
}


@media (max-width:991px) {
    .full-width-card2 .full-width-card-2-img {
        border-radius: 8px 8px 0 0;
    }

    .card-4>img {
        width: 200px;
        margin-bottom: 32px;
    }

    .small-card1,
    .small-card2 {
        height: 260px;
    }

    .small-card1 h3,
    .small-card2 h3 {
        font-size: 50px;
    }

    .small-card1 h4,
    .small-card2 h4 {
        font-size: 24px;
    }

    .full-width-card3 h3 {
        text-align: center;
    }

    .full-width-card3 p {
        text-align: center;
    }
}

@media (max-width:767px) {
    .card-1 h3 {
        font-size: 24px;
    }

    .card-1 p {
        font-size: 16px;
    }

    .card-1 .fs_20 {
        font-size: 18px;
    }

    .card-2 p {
        font-size: 16px;
    }

    .card-3>img {
        aspect-ratio: unset;
        height: 350px;
    }

    .card-3 h3 {
        font-size: 20px;
    }

    .card-4>img {
        width: 175px;
    }

    .full-width-card1,
    .full-width-card2 {
        border-radius: 0;
    }

    .full-width-card2 .full-width-card-2-img {
        border-radius: 0;
        height: 210px;
    }

    .full-width-card2 h3 {
        font-size: 20px;
    }

    .full-width-card1 p,
    .full-width-card2 p {
        font-size: 16px;
    }

    .full-width-card3 h3 {
        font-size: 22px;
    }

    .full-width-card3 p {
        font-size: 16px;
    }

    .full-width-card4 h3 {
        font-size: 20px;
    }

    .full-width-card4 .fs_20 {
        font-size: 16px;
    }

}