

/* Base Styles */
.second-opinion {
  overflow-x: hidden;
  overflow-y: hidden;
}

.info1 {
  margin-top: 3rem;
}

.info2 {
  background-color: rgba(246, 241, 231, 1);
}

.info1,
.info2 {
  padding: 1rem 3rem;
}

.info1-heading,
.info2-heading {
  padding: 1rem 0;
}

.info1-text,
.info2-text {
  font-size: 1.05rem;
  font-weight: 400;
  border: none;
}

.info3 {
  margin: 3rem 22vw;
  padding: 1.5rem;
  border: 1px solid rgba(220, 125, 87, 1);
}

.info3-text {
  font-size: 1.2rem;
  line-height: 2rem;
}

