/* Base Styles */
.banner-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background-position: center;
  object-fit: cover;
  padding: 8rem;
  aspect-ratio: 16 / 9;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1;
}

.banner-heading {
  position: relative;
  color: white;
  font-size: 2.5rem;
  font-weight: 550;
  z-index: 20;
  height: auto;
  word-wrap: break-word;
}

.banner-button {
  position: relative;
  border-radius: 6vw;
  margin-top: 5vw;
  width: 20vw;
  background-color: #f5e0b8;
  color: black;
  border: none;
  font-size: 1.7rem;
  font-weight: 450;
  height: min(9vh, 8vw);
  font-family: "Playfair Display";
  z-index: 2;
}
.mobile-banner-heading,.mobile-banner-button{
display: none;
}
.banner-quote {
  background-color: rgba(246, 241, 231, 1);
  padding: 5rem 1rem;
}

.banner-quote-text {
  text-align: center;
  color: black;
  font-size: 1.5em;
  font-weight: 550;
  padding: 0rem;
  line-height: 3rem;
}

.doublequote {
  color: rgba(220, 125, 87, 1);
  font-weight: 600;
  font-size: 2em;
  margin: 1.5rem 0rem;

}

/* Media Queries for Tablets */
@media screen and (max-width: 768px) {
  .banner-container {
    height: 50vh;
    padding: 4rem;
  }

  .banner-heading {
    font-size: 6vw;
  }

  .banner-button {
    width: 50vw;
    font-size: 4vw;
    margin-top: 5vw;
  }

  .banner-quote-text {
    font-size: 1.5em;
  }

}

/* Media Queries for Mobile Phones */
@media screen and (max-width: 480px) {
  .banner-container {
    height: 40vh;
    padding: 2rem;
    object-fit: cover;
  }

  .banner-heading {
    font-size: 8vw;
    display:none;
  }

  .banner-button {
    display: none;
  }
  .mobile-banner-heading{
    display: block;
    color: black;
    font-size: 5.3vw;
    font-weight: 600;
    height: auto;
    word-wrap: break-word;
    padding: 1rem;
    text-align: center;
    }
  .mobile-banner-button{
      display: block;
      border-radius: 6vw;
      margin-top: 6vw;
      width: 45vw;
      margin: 5% 25%;
      background-color: #f5e0b8;
      color: black;
      border: none;
      font-size: 1.5rem;
      font-weight: 450;
      height: 3rem;
      font-family: "Playfair Display";
      padding: 2px;
      }
  .banner-quote {
    padding: 1rem 1.2rem;
    width: 100%;
  }

  .banner-quote-text {
    font-size: 1.2em;
    margin: 1rem 0;
    text-align: center;
    line-height: 1.5rem;
    padding: 0rem 1rem;
    font-weight: 500;

  }
  .quote-break{
    display: none;
  }
  .doublequote{
    margin: 0.2rem;
    padding: 0;
  }

}
