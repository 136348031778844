.phone-number-input {
  display: flex;
}

.phone-number-input .my-select {
  width: auto;
  min-width: 20%;
  max-width: 40%;
}

.phone-number-input select {
  width: 100%;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.phone-number-input fieldset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pink-select select {
  background: #e9967538;
  color: var(--pink);
}

.pink-select .my-select::after {
  color: var(--pink);
}
