.pricing-heading {
  margin: 4rem 0;
  text-align: center;
  font-size: 2.3rem;
  font-weight: 600;
  font-family: "Playfair Display";
}

.pricing-cards-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 12rem;
  flex-wrap: wrap;
}

.pricing-card {
  border: 0.2px solid rgba(217, 217, 217, 1);
  background-color: rgba(217, 217, 217, 0.15);
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  align-items: start;
  text-align: left;
  width: 30vw;
  margin: 1rem;
}

.pricing-card-heading {
  text-align: center;
  font-weight: 550;
  font-size: 1.5rem;
  margin-left: 20%;
}

.pricing-card-bullets {
  margin-top: 1rem;
  text-align: left;
  padding: 0rem;
}

.pricing-card-bullet {
  display: flex;
  gap: 1rem;
  margin: 0.5rem 2rem;
  padding: 0.4rem 0rem;
}

.pricing-card-bullet img {
  height: 2rem;
}

.card-price {
  background-color: rgba(210, 231, 219, 0.6);
  color: rgba(33, 61, 104, 1);
  font-size: 1.6rem;
  font-family: "Playfair Display";
  font-weight: 550;
  text-align: center;
  height: 60px;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 100%;
  margin-top: auto;
}
.aerial-price{
  font-family: "Arial";
  color: rgba(33, 61, 104, 1);
  font-size: 1.6rem;
  font-weight: 500;
}
.pricing-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 2rem;
  background-color: rgba(220, 125, 87, 1);
  color: white;
  padding: 1rem 2rem;
  margin: 5rem auto;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 1);
  width: 400px;
  height: 3rem;
}

.card-button h5 {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.card-button img {
  height: 1rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .pricing-cards-container {
    padding: 1rem 5rem;
  }

  .pricing-card {
    width: 45vw;
  }
}

@media (max-width: 768px) {
  .pricing-cards-container {
    padding: 1rem 2rem;
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 80vw;
  }

  .pricing-button {
    width: 90vw;
  }
}

@media (max-width: 480px) {
  .pricing-heading {
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  .pricing-cards-container {
    padding: 1rem 0rem;
  }

  .pricing-card {
    width: 85%;
    padding-top: 1rem;
    border-radius: 2rem;
  }

  .pricing-card-heading {
    font-size: 1.2rem;
    margin-left: 0;
    text-align: center;
    width: 100%;
  }

  .pricing-card-bullet img {
    height: 1.5rem;
  }

  .card-price {
    font-size: 1.5rem;
    height: auto;
    padding: 0.5rem 0;
    border-radius: 0%;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;


  }

  .pricing-button {
    width: 50vw;
    border: none;
    padding: 0.6rem 0.5rem 0.5rem 0.5rem;
  }

  .pricing-button h5 {
    font-size: 4vw;
    font-weight: 350;
  }

  .pricing-button img {
    height: 0.8rem;
    width: 10vw;
  }
}
