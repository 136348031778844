.we-can-help-sec {
    border: 2px solid #dee2e6;
    border-radius: 8px;
}

@media (min-width:1200px) {
    .schedule-demo .related-queries-width {
        width: 95%;
    }
}

@media (min-width:1400px) {
    .we-can-help-sec-width {
        width: 93%;
    }
}

@media (min-width:1600px) {
    .we-can-help-sec-width {
        width: 80%;
    }

    .schedule-demo .related-queries-width {
        width: 85%;
    }
}

@media (min-width:1800px) {
    .we-can-help-sec-width {
        width: 73%;
    }
}

@media (max-width:991px) {
    .we-can-help-sec {
        border: none;
        border-radius: none;
    }

    .we-can-help-sec .row .col>div:first-child {
        margin-bottom: 32px;
    }

    .we-can-help-sec .row .col>div {
        border: 2px solid #dee2e6;
        border-radius: 8px;
    }
}