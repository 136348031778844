.what-we-do-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
}

@media (max-width:991px) {
    .what-we-do-steps {
        flex-direction: column;
    }
}