.pre-heading {
  text-align: center;
  margin-top: 2rem;
  font-family: "Playfair Display";
  font-weight: 425;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

.commonly-requested-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Playfair Display";
  margin-top: 5rem;
}
.all-types-container{
  margin-bottom: 5rem;
  margin-top: 5rem;
}
.type-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem 8rem;
}

.type-container2 {
  padding: 0rem 18rem;
}

.type-name {
  border: none;
  width: 15rem;
  height: 4rem;
  border-radius: 3rem;
  margin: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.type-name h3 {
  font-size: 1.3rem;
}

@media (max-width: 1200px) {
  .type-name {
    width: 13rem;
    height: 3.5rem;
  }
}

@media (max-width: 800px) {
  .type-container2 {
    padding: 0 4rem;
  }
  .type-name {
    width: 11rem;
    height: 3rem;
  }
}

@media (max-width: 480px) {
  .pre-heading {
    font-size: 1.8rem;
    padding: 0rem 1rem;
    font-weight: 400;
  }
  .commonly-requested-heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0rem 0.5rem;
    font-family: 'Open Sans', sans-serif;

  }
  .type-container {
    padding: 1rem;
  }
  .type-container2{
    padding: 0rem 1rem;
  }

  .type-name {
    width: 9.4rem;
    height: 2.2rem;
    padding-top: 0.2rem;
  }
  .type-name h3{
    font-size: 1rem;
    font-weight: 425;
  }

}
