.my-footer {
  background-color: var(--pitch);
}

.my-footer .container {
  padding-top: 80px;
  padding-bottom: 60px;
}

.footer-menu {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.footer-menu li {
  margin-bottom: 15px;
}

.footer-menu li:last-child {
  margin-bottom: 0;
}

.book-a-demo {
  display: grid;
  place-items: center;
  background-color: #000;
  border-radius: 4px;
  width: 235px;
  height: 55px;
  max-width: 100%;
}

.book-a-demo p {
  font-family: "Playfair Display", serif;
  color: #fff;
  margin: 0;
  font-weight: 500;
  padding: 12px;
}

@media (min-width: 991px) and (max-width: 1199px) {
  .footer-btn {
    font-size: 14px;
  }

  .footer-btn img {
    width: 35px;
  }
}

@media (max-width: 991px) {
  .footer-items-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .footer-menu li,
  .my-footer p:not(.book-a-demo p) {
    font-size: 14px;
  }
}
