.figure-card h3 {
    font-size: 60px;
    color: var(--pink);
}

.figure-card h3>* {
    color: var(--pink);
}

@media (max-width:1599px) {
    .figure-card h3 {
        font-size: 45px;
    }
}

@media (max-width:1299px) {
    .figure-card h3 {
        font-size: 40px;
    }
}

@media (max-width:991px) {
    .figure-card h3 {
        font-size: 35px;
    }
}

@media (max-width:767px) {
    .figure-card p {
        font-size: 16px;
    }
}