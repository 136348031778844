:root {
  --pink: #e99675;
  --green: #bfddcb;
  --text-green: #119044;
  --linen: #ffefe2;
  --light-blue: #f2f6fa;
  --pitch: #f6f1e7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  color: #000;
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px;
  }
}

@media (min-width: 1800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1670px;
  }
}

/* fonts */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

.font-playfair {
  font-family: "Playfair Display", serif;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.heading-1 {
  font-size: 60px;
  font-weight: 700;
}

.heading-2 {
  font-size: 48px;
  font-weight: 600;
}

.heading-3 {
  font-size: 40px;
  font-weight: 600;
}

.heading-4 {
  font-size: 36px;
  font-weight: 600;
}

.heading-5 {
  font-size: 32px;
  font-weight: 600;
}

.text-1 {
  font-size: 32px;
}

.text-2 {
  font-size: 28px;
}

.text-3 {
  font-size: 24px;
}

.text-4 {
  font-size: 22px;
}

@media (max-width: 1399px) {
  .heading-1 {
    font-size: 50px;
  }

  .heading-2 {
    font-size: 40px;
  }

  .heading-3 {
    font-size: 30px;
  }

  .heading-4 {
    font-size: 28px;
  }

  .heading-5 {
    font-size: 24px;
  }

  .text-1 {
    font-size: 26px;
  }

  .text-2 {
    font-size: 24px;
  }

  .text-3 {
    font-size: 22px;
  }

  .text-4 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .heading-1 {
    font-size: 42px;
  }

  .heading-2 {
    font-size: 34px;
  }

  .heading-3 {
    font-size: 30px;
  }

  .heading-4 {
    font-size: 24px;
  }

  .heading-5 {
    font-size: 22px;
  }

  .text-1 {
    font-size: 23px;
  }

  .text-2 {
    font-size: 20px;
  }

  .text-3 {
    font-size: 20px;
  }

  .text-4 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .heading-1 {
    /* font-size: 30px; */
    font-size: 22px;
  }

  .heading-2 {
    /* font-size: 26px; */
    font-size: 22px;
  }

  .heading-3 {
    font-size: 24px;
  }

  .heading-4 {
    font-size: 20px;
  }

  .heading-5 {
    font-size: 18px;
  }

  .text-1 {
    font-size: 18px;
  }

  .text-2 {
    font-size: 18px;
  }

  .text-3 {
    /* font-size: 16px; */
    font-size: 18px;
  }

  .text-4 {
    font-size: 16px;
  }
}

.fs_28 {
  font-size: 28px;
}

.fs_26 {
  font-size: 26px;
}

.fs_24 {
  font-size: 24px;
}

.fs_22 {
  font-size: 22px;
}

.fs_20 {
  font-size: 20px;
}

.fs_18 {
  font-size: 18px;
}

.fs_16 {
  font-size: 16px;
}

.fs_14 {
  font-size: 14px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.color-white {
  color: #fff !important;
}

.color-pink {
  color: var(--pink) !important;
}

.color-green {
  color: var(--text-green) !important;
}

.color-blue {
  color: #0e3768 !important;
}

/* backgrounds */
.bg-pink {
  background-color: var(--pink);
}

.bg-green {
  background-color: var(--green);
}

.bg-linen {
  background-color: var(--linen);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-pitch {
  background-color: var(--pitch);
}

.bg-white {
  background-color: #fff;
}

.bg-light-gray {
  background-color: #f8f8f8;
}

/* inputs */
.input-1 {
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 16px 18px;
  color: #807f7f;
}

.input-1 ::placeholder {
  color: #807f7f;
}

.input-2,
.input-2:is(:focus) {
  border: 1px solid rgba(187, 187, 187, 40%);
  border-radius: 4px;
  padding: 15px 14px;
  outline: 0;
}

.my-select {
  position: relative;
}

.my-select select {
  appearance: none;
  padding-right: 30px;
  width: 100%;
}

.my-select::after {
  font-family: "Font Awesome 6 free";
  content: "\f078";
  font-weight: 900;
  font-size: 13px;
  color: #807f7f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
}

@media (max-width: 991px) {
  .input-1 {
    padding: 10px 14px;
  }
}

/* mui input fields */
.mui-tex-field .MuiInputLabel-root.Mui-focused {
  color: var(--pink) !important;
}

.mui-tex-field fieldset {
  border-color: rgba(187, 187, 187, 40%) !important;
}

.mui-tex-field .Mui-focused fieldset {
  border-color: var(--pink) !important;
}

.mui-check-box * {
  color: rgba(187, 187, 187, 40%) !important;
}

.mui-check-box.Mui-checked * {
  color: var(--text-green) !important;
}

/* others */
a {
  color: #000;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.border-none {
  border: transparent !important;
}

.border-dim {
  border: 1px solid rgba(187, 187, 187, 40%);
}

.container-padding {
  padding-left: 12px;
  padding-right: 12px;
}

.my-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.heading-main {
  margin-bottom: 80px;
  text-align: center;
}

@media (min-width: 1200px) {
  .my-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading-main {
    margin-bottom: 100px;
  }
}

/* card container width */
@media (min-width: 1600px) {
  .card1-width {
    width: 87%;
  }
}

@media (min-width: 1800px) {
  .card1-width {
    width: 80%;
  }
}

/* change btn */
.change-article-btn,
.change-article-btn:is(:active, :focus-visible, :hover) {
  background-color: #f7f7f7;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 25px;
  color: #685e5e;
}

@media (min-width: 1600px) {
  .change-article-btn,
  .change-article-btn:is(:active, :focus-visible, :hover) {
    padding: 11px 22px;
    font-size: 27px;
  }
}

@media (max-width: 991px) {
  .change-article-btn,
  .change-article-btn:is(:active, :focus-visible, :hover) {
    padding: 6px 16px;
    font-size: 23px;
  }
}

@media (max-width: 991px) {
  .change-article-btn,
  .change-article-btn:is(:active, :focus-visible, :hover) {
    padding: 6px 14px;
    font-size: 18px;
  }
}

/* Accordion */
.accordion-item {
  border: none;
}

.accordion-button {
  background-color: #fff;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: var(--light-blue);
  box-shadow: none;
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

@media (max-width: 991px) {
  .accordion-button::after {
    height: 15px;
    width: 15px;
    background-size: 15px;
  }
}

/* my new css */

.pro-card {
  padding: 32px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px #0000001a;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .pro-card {
    padding: 24px;
  }
}

.model-card {
  max-width: 480px;
}

/* new css */
.bg {
  background-color: #ebedef;
}

.bg1 {
  background-color: #f6f1e7;
}

.text {
  text-align: right;
}

.mt-5 {
  margin-top: 6rem !important;
}

.mt-6 {
  margin-top: 9rem !important;
}

.custom-input {
  padding: 8px;
}

.list-group-item.active {
  background-color: #e99675;
  border: none;
  border-radius: 0px;
}

.list-group-item {
  background-color: #f6f1e7;
  border: none;
  border-radius: 0px;
}

.logo img {
  width: 150px;
  height: 70px;
  margin-left: 33%;
}

.para1 {
  margin-top: 20px;
}

.h-1 {
  height: 100vh;
}

.w-98 {
  width: 98%;
}

.w-82 {
  width: 82%;
}

.ml-2 {
  margin-left: 25px;
}

.bg-new {
  background-color: #bfddcb !important;
}

.mt {
  margin-top: 60px;
}

.mr-new {
  margin-left: -86px;
}

.btn-new {
  padding: 10px;
  border-radius: 35px;
  width: 33%;
  margin-right: 35%;
}

.popup-container {
  width: 30%;
  position: absolute;
  top: 30%;
  right: 35%;
}

.head-new {
  padding: 20px;
  background-color: white;
  width: 100%;
}

.in-box {
  font-size: 0.875rem;
  line-height: 2.4rem;
  margin-bottom: 0.5rem;
}

.btn-new2 {
  border-radius: 37px;
  width: 12%;
  margin-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .w-82 {
    width: 73%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-new2 {
    border-radius: 37px;
    width: 15%;
    margin-left: 20px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
.editor-container {
  border: 1px solid black;
  margin-bottom: 20px;
}
