.privacy-policy-container{
    max-width: 80vw;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #FFEFE24D;
}

.privacy-policy-heading{
    font-family: Playfair Display;
    font-size: 30px;
    font-weight: 550;
    line-height: 53.32px;
    text-align: center; 
}

.privacy-policy-content{
    margin-top: 50px;
    line-height: 1.6;
    font-size: 1rem;
}
.privacy-policy-content h1{
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1.5rem;
}
.privacy-policy-content a{
    color: rgb(6, 6, 189);
    cursor: pointer;
}