@media (min-width: 992px) {
  .insurers-banner-title-width {
    width: 84%;
  }

  .insurer-p-width {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .insurers-banner-title-width {
    width: 71%;
  }

  .insurer-p-width {
    width: 70%;
  }
}

@media (min-width: 1400px) {
  .insurers-banner-title-width {
    width: 73%;
  }

  .insurer-p-width {
    width: 75%;
  }
}

@media (min-width: 1600px) {
  .insurers-banner-title-width {
    width: 63%;
  }

  .insurer-p-width {
    width: 60%;
  }
}

@media (min-width: 1800px) {
  .insurers-banner-title-width {
    width: 57%;
  }

  .insurer-p-width {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .insurers-banner .main-banner {
    background-position: 22% !important;
  }

  .insurers-banner .title-container-sm div {
    background-color: #fff;
    margin-top: -25px;
  }

  .insurers-pitch-board p {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .insurers-banner .main-banner {
    background-position: 65% !important;
  }
}
