.pitch-bord {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pitch-bord img {
    width: 70px;
}

@media (max-width:767px) {
    .pitch-bord img {
        width: 50px;
    }
}