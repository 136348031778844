.my-tabs {
    display: grid;
    grid-template-columns: repeat(7, auto);
}

.my-tabs>div {
    padding: 12px 16px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.my-tabs>div {}

.my-tabs>div.active {
    background-color: var(--light-blue);
}

.my-tab-items>div {
    display: none;
}

.my-tab-items>div.active {
    display: block;
}

@media (max-width:1399px) {
    .my-tabs>div {
        font-size: 16px;
    }
}

@media (max-width:1199px) {
    .my-tabs>div {
        font-size: 14px;
    }
}

@media (max-width:991px) {
    .my-tabs {
        grid-template-columns: repeat(4, auto);
        margin-bottom: 12px;
    }

    .my-tabs>div {
        text-align: left;
    }
}

@media (max-width:767px) {
    .my-tabs {
        grid-template-columns: repeat(3, auto);
    }
}

@media (max-width:575px) {
    .my-tabs {
        grid-template-columns: repeat(1, auto);
    }
}