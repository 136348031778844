.article-container {
    max-width: 80vw;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #FFEFE24D;
  }
  
  .article-banner .main-banner {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    margin-bottom: 40px;
  }
  .article-heading{
    font-family: Playfair Display;
    font-size: 30px;
    font-weight: 550;
    line-height: 53.32px;
    text-align: center;    
  }
 
  .article-body {
    margin-top: 50px;
    line-height: 1.6;
    font-size: 1rem;
  }
  .custom-h1 {
    font-size: 24px; /* Adjust this size as needed */
  }
  
  .custom-h2 {
    font-size: 20px; /* Adjust this size as needed */
  }
  
  .custom-h3 {
    font-size: 18px; /* Adjust this size as needed */
  }
  
  .custom-h4 {
    font-size: 16px; /* Adjust this size as needed */
  }
  
  .custom-h5 {
    font-size: 14px; /* Adjust this size as needed */
  }
  
  .custom-h6 {
    font-size: 12px; /* Adjust this size as needed */
  }
  