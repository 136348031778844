.user-main-content {
  margin-top: 91px;
}

@media (max-width: 1199px) {
  .user-main-content {
    margin-top: 77px;
  }
}

@media (max-width: 767px) {
  .user-main-content {
    margin-top: 73px;
  }
}
