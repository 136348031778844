.support-card {
    background-color: #fff;
    border-bottom: 6px solid var(--pink);
    border-radius: 6px;
    min-width: 300px;
    padding: 38px 28px;
}

@media (max-width:1199px) {
    .support-card {
        min-width: 200px;
        padding: 28px 18px;
    }
}

@media (max-width:991px) {
    .support-card {
        min-width: 160px;
        padding: 24px 18px;
    }
}

