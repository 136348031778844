.question-model-input-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}
.question-model-input-sec .input-sec-inner {
  display: flex;
  align-items: center;
  gap: 16px;
}
