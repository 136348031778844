.pink-submit-btn,
.pink-submit-btn:is(:active, :focus-visible, :hover) {
  font-family: "Open Sans", sans-serif !important;
  border-radius: 4px !important;
}

.green-submit-btn,
.green-submit-btn:is(:active, :focus-visible, :hover) {
  font-family: "Open Sans", sans-serif !important;
  font-size: 24px;
  background-color: var(--green) !important;
  border-radius: 4px !important;
}

@media (max-width: 767px) {
  .green-submit-btn,
  .green-submit-btn:is(:active, :focus-visible, :hover) {
    font-size: 20px;
    color: #fff !important;
    background-color: #5ee2a0 !important;
  }
}

.spinner-border {
  --bs-spinner-width: 24px;
  --bs-spinner-height: 24px;
  --bs-spinner-border-width: 2px;
}
