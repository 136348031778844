.btn-primary,
.btn-primary:is(:active, :focus-visible, :hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px 20px;
    border-radius: 50px;
    border: none;
    color: #000 !important;
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-weight: 500;
    background-color: var(--pink) !important;
    box-shadow: none;
    padding: 10px 30px;
}

.btn-secondary,
.btn-secondary:is(:active, :focus-visible, :hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px 20px;
    border-radius: 50px;
    border: none;
    color: #000 !important;
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-weight: 500;
    background-color: var(--green) !important;
    box-shadow: none;
    padding: 10px 30px;
}

.btn-tertiary,
.btn-tertiary:is(:active, :focus-visible, :hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px 20px;
    border-radius: 50px;
    border: 2px solid #fff;
    color: #fff !important;
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-weight: 500;
    background-color: transparent !important;
    box-shadow: none;
    padding: 10px 30px;
}

.btn-pitch,
.btn-pitch:is(:active, :focus-visible, :hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px 20px;
    border-radius: 50px;
    border: none;
    color: #000 !important;
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-weight: 500;
    background-color: #F5E0B8 !important;
    box-shadow: none;
    padding: 10px 30px;
}

.btn-white,
.btn-white:is(:active, :focus-visible, :hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3px 20px;
    border-radius: 50px;
    border: 1px solid #000;
    color: #000 !important;
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-weight: 500;
    background-color: #f8f8f8 !important;
    box-shadow: none;
    padding: 10px 30px;
}


.btn-primary img,
.btn-secondary img,
.btn-tertiary img,
.btn-pitch img,
.btn-white img {
    width: 60px;
    object-fit: cover;
}


@media (min-width:768px) {

    .btn-lg,
    .btn-lg:is(:active, :focus-visible, :hover) {
        padding-right: 48px;
        padding-left: 48px;
    }
}

@media (max-width:767px) {

    .btn-primary img,
    .btn-secondary img,
    .btn-tertiary img,
    .btn-pitch img,
    .btn-white img {
        width: 45px;
        object-fit: cover;
    }

    .btn-lg,
    .btn-lg:is(:active, :focus-visible, :hover) {
        font-size: 16px;
    }

    .arrow-sm-none img {
        display: none;
    }
}