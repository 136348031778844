.support-framework-container {
  background-color: rgba(248, 248, 248, 1);
  padding: 1rem 0;
}

.support-framework-heading {
  margin: 4rem 0;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem 4rem;
  gap: 3rem;
}

.card {
  flex: 1 1 calc(33.333% - 2rem);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  box-shadow: none;
  border-radius: 0;
  width:370px ;
  height: 400px;
}

.card-heading {
  width: 100%;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin: 0 33%;
  padding: 1rem 3rem;
}

.card-desc {
  font-weight: 400;
  text-align: left;
  flex-grow: 1;
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
  padding: 1rem 3rem;
}

.card-button {
  box-shadow: none;
  display: flex;
  height: 2.8rem;
  align-items: center;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 1.2rem 0.4rem 1.5rem 1.5rem;
  border: none;
  gap: 1rem;
  border-radius: 4rem;
  background-color: rgba(233, 150, 117, 1);
  color: white;
  cursor: pointer;
  width: 80%;
}

.card-button img {
  margin-left: 0.5rem;
  width: 3rem;
  padding: 0.2rem 0rem 0rem 0rem;
}

.card-button h5 {
  margin: 0;
  font-size: 1rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .card {
    flex: 1 1 calc(45% - 2rem); /* 2 cards per row */
  }
}

@media (max-width: 1050px) {
  .cards-container {
    flex-direction: column; /* Stack cards vertically */
    padding: 2rem 1rem;
  }

  .card {
    flex: 1 1 100%; /* Full width */
    margin: 1rem 0;
  }
}
@media (max-width: 480px) {
  .cards-container {
    flex-direction: column; 
    padding: 2rem 1rem;
  }

  .card {
    flex: 1 1 90%; 
    margin: 1rem 0;
    width: 320px;
  }
  .card-heading{
    text-align: left;
    margin-bottom: 0.1rem;
    font-size: 1.4rem;
    line-height: normal;
  }
  .card-desc{
    padding: 1rem 2rem;
    margin-top: 0.5rem;
  }
  .card-button{
    box-shadow: none;
  display: flex;
  height: 2.5rem;
  align-items: center;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4rem;
  background-color: rgba(233, 150, 117, 1);
  color: white;
  cursor: pointer;
  width: 50vw;
  }
}