.card2-height {
    height: calc(100% - 60px);
}

.border-b-green {
    display: inline-block;
    width: 75px;
    border-bottom: 2px solid var(--text-green);
}

@media (min-width:1400px) {
    .cancer-check-width{
        width: 90%;
    }
}

@media (min-width:1600px) {
    .cancer-check-width{
        width: 78%;
    }
}

@media (min-width:1800px) {
    .cancer-check-width{
        width: 71%;
    }
}