.side-bar-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  width: 255px;
  height: 100vh;
  background-color: #fff;
  padding: 24px;
  transition: all 500ms ease-in-out;
  z-index: 11;
}

.side-bar-menu.active {
  left: 0 !important;
}

.my-nav .side-bar-menu .navbar-brand img {
  width: 123px;
}

.side-bar-menu .close-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-40%);
  font-size: 22px;
}

.side-bar-menu ul {
  flex-grow: 1;
  overflow-y: auto;
}

#overlay1 {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 500ms ease-in-out;
  background: rgba(0, 0, 0, 0.58);
  z-index: 10;
}

@media (min-width: 1200px) {
  .side-bar-menu {
    display: none;
  }
}
