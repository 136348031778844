.home-banner {
  width: 100%;
  max-height: calc(100vh - 91px);
  aspect-ratio: 2 / 1;
  background: linear-gradient(
      rgba(000, 000, 000, 0.22),
      rgba(000, 000, 000, 0.22)
    ),
    url("../../assets/images/home-banner-img.png");
  background-size: cover !important;
  display: flex;
  align-items: center;
}

.main-banner {
  width: 100%;
  max-height: calc(100vh - 91px);
  aspect-ratio: 2 / 1;
  background-size: cover !important;
  display: flex;
  align-items: center;
}

.treatments-banner {
  width: 100%;
  max-height: calc(100vh - 91px);
  aspect-ratio: 2 / 1;
  background: linear-gradient(
      rgba(000, 000, 000, 0.22),
      rgba(000, 000, 000, 0.22)
    ),
    url("../../assets/images/treatments-banner-img.png");
  background-size: cover !important;
  display: flex;
  align-items: center;
}

.banner-p-sm {
  color: #454444;
}

@media (min-width: 992px) {
  .banner-btn {
    padding: 10px 35px !important;
  }

  .home-banner-text-width {
    width: 58%;
  }

  .treatments-banner-text-width {
    width: 85%;
  }
}

@media (min-width: 1200px) {
  .home-banner-text-width {
    width: 57%;
  }

  .treatments-banner-text-width {
    width: 70%;
  }
}

@media (min-width: 1400px) {
  .banner-btn {
    padding: 10px 50px !important;
  }

  .home-banner-text-width {
    width: 53%;
  }

  .treatments-banner-text-width {
    width: 75%;
  }
}

@media (min-width: 1600px) {
  .home-banner-text-width {
    width: 46%;
  }

  .treatments-banner-text-width {
    width: 65%;
  }
}

@media (min-width: 1800px) {
  .home-banner-text-width {
    width: 42%;
  }

  .treatments-banner-text-width {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .home-banner,
  .main-banner,
  .treatments-banner {
    max-height: calc(100vh - 77px);
  }
}

@media (max-width: 767px) {
  .home-banner,
  .main-banner,
  .treatments-banner {
    max-height: 60vh;
    aspect-ratio: 1.5 / 1;
  }

  .home-banner {
    background-position: 83%;
  }

  .treatments-banner {
    background-position: 75%;
  }
}
