.user-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.user-nav .navbar {
  background-color: #fff;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
}

.user-nav .container-fluid {
  width: 95%;
}

.user-nav .navbar-brand {
  padding-top: 8px;
  padding-bottom: 0;
  margin-right: 28px;
}

.user-nav .navbar-brand img {
  width: 138px;
}

.user-nav .navbar-toggler {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: none;
  font-size: 25px;
  color: #000;
}

.user-nav .navbar-toggler:is(:focus, :focus-visible) {
  outline: none;
  box-shadow: none;
}

.user-nav .navbar-collapse {
  justify-content: end;
}

.user-nav .nav-link {
  position: relative;
  color: #000;
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  white-space: nowrap;
}

.user-nav .nav-link.active {
  color: #000;
}

.user-nav .menu-active-border {
  position: absolute;
  bottom: 3px;
  left: 8px;
  display: block;
  visibility: hidden;
  width: 40px;
  height: 2px;
  border-bottom: 2px solid #f5e0b8;
}

.user-nav .nav-link.active .menu-active-border {
  visibility: visible;
}

@media (min-width: 1200px) {
  .user-nav .navbar {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .user-nav .nav-link {
    margin: 0 8px;
  }
}

@media (min-width: 1400px) {
  .user-nav .nav-link {
    margin: 0 20px;
  }
}

@media (min-width: 1600px) {
  .user-nav .nav-link {
    margin: 0 32px;
  }
}

/* @media (min-width: 1500px) {
  .user-nav .navbar-collapse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
} */

@media (max-width: 1199px) {
  .user-nav .navbar .container-fluid {
    justify-content: center;
  }

  .user-nav .navbar-nav .nav-item {
    margin-bottom: 16px;
  }

  .user-nav .navbar-brand {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .user-nav .navbar-brand img {
    width: 130px;
  }

  .user-nav .nav-link {
    font-size: 16px;
  }
}
