.core-pillars-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.core-pillars-card img {
    width: 170px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border: 3px solid var(--pink);
    border-radius: 50%;
}

.core-pillars-card .pillar-border {
    display: inline-block;
    width: 75px;
    border-bottom: 2px solid var(--pink);
}

@media (min-width:768px) {
    .core-pillars>div:nth-child(1) img {
        border-color: #D9D9D9;
    }

    .core-pillars>div:nth-child(3) img {
        border-color: #FFEFE2;
    }
}

@media (max-width:1399px) {
    .core-pillars-card img {
        width: 150px;
    }
}

@media (max-width:991px) {
    .core-pillars-card img {
        width: 125px;
    }
}

@media (max-width:768px) {
    .core-pillars>div:first-child img {
        border-color: #D9D9D9;
    }

    .core-pillars>div:last-child img {
        border-color: #FFEFE2;
    }
}