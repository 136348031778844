@media (min-width:1200px) {
    .contact-us-sec .related-queries-width {
        width: 85%;
    }
}

@media (min-width:1400px) {
    .contact-us-sec .related-queries-width {
        width: 70%;
    }
}

@media (min-width:1600px) {
    .contact-us-sec .related-queries-width {
        width: 63%;
    }
}

@media (max-width:991px) {
    .contact-sec {
        border: 2px solid #dee2e6;
        border-radius: 8px;
        margin-bottom: 32px;
    }
}