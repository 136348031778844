.border-b {
    display: inline-block;
    width: 80px;
    border-bottom: 2px solid var(--pink);
}

@media (max-width:767px) {
    .employers-tab .fs_20 {
        font-size: 16px;
    }
}