.process-page{
  background-color: rgba(248, 248, 248, 1);
padding: 2rem;
}
.process-heading {
    text-align: center;
    font-weight: 600;
    margin-top: 3rem;
  }
  .process-heading p {
    font-size: 2.5rem;
    font-family: 'Playfair Display';
    text-wrap: wrap;
  }
  .process-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 0rem 5rem;
  }
  
  .process {
    width: 40vw;
  }
  
  .process-heading {
    font-size: 1.8rem;
    white-space: nowrap;
    margin-bottom: 2rem;
  }
  
  .stat-number1,
  .stat-number2 {
    font-size: 2rem;
  }
  
  .stat-number1 {
    color: rgba(233, 150, 117, 1);
  }
  
  .stat-number2 {
    color: rgba(17, 144, 68, 1);
  }
  
  .process-img {
    height: 18vw;
    width: 30vw;
    object-fit: cover;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    margin-top: 1rem;
  }
  
  .process-title {
    font-size: 1.3rem;
    font-weight: 650;
    color: #3D3D3D;
    margin-top: 1rem;
  }
  
  .steps-container {
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
  }
  
  .step-card {
    height: 240px;
    width: 250px;
    border: 1px solid rgba(185, 185, 185, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2.5rem 1rem;
    margin: 0.5rem;
    margin-bottom: 5rem;
    text-wrap: wrap;
  }
  
  .step-card p {
    color: rgba(233, 150, 117, 1);
    font-size: 2.2rem;
  }
  
  .step-card h5 {
    margin-top: 1rem;
    font-weight: 550;
  }
  .process-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
  }
  .process-button {
    color: black;
    background-color: rgba(191, 221, 203, 1);
    width: 400px;
    height: 3rem;
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Playfair Display';
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem 2rem;
    margin-bottom: 2rem;
    text-wrap: nowrap;
    margin: 0 33%;
  }

  /* Media Queries for Responsive Design */
  @media screen and (max-width: 1024px) {
    .process-container {
      flex-direction: column;
      padding: 0rem 2rem;
    }
  
    .process {
      width: 80vw;
    }
  
    .process-img {
      width: 100%;
      height: auto;
    }
    .process-button{
      margin: 0 4rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .process-container {
      padding: 0rem 1rem;
    }
  
    .process {
      width: 100%;
    }
  
    .process-heading {
      font-size: 1.5rem;
    }
  
    .process-title {
      font-size: 1rem;
    }
  
    .steps-container {
      flex-direction: column;
    }
  
    .step-card {
      width: 80%;
      margin: 0.5rem auto;
    }
  
    .process-button {
      width: 80%;
      font-size: 1rem;
    }
    .process-button img{
      height: 1.4rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .process-heading p {
      font-size: 6.5vw;
      margin-top: 3rem;
      padding: 0;
      width: 100%;
    }
    .process-heading {
      font-size: 1.2rem;
      margin-top: 1rem;
    }
  
    .stat-number1,
    .stat-number2 {
      font-size: 1.5rem;
    }
  
    .process-img {
      border-radius: 1rem;
    }
  
    .process-title {
      font-size: 1.2rem;
      font-weight: 550;
    }
  
    .step-card {
      width: 50%;
      height: fit-content;
      border-radius: 1rem;
    }
  
    .step-card p {
      font-size: 1.5rem;
    }
    .process-button-container{
     
      margin: 10% 0;
    }
    .process-button img{
      width: 35%;
      padding-top: 0rem;
      padding-bottom: 0.2rem;
    }
   
  }
  