.assessment-banner > div {
  min-height: 200px;
  display: grid;
  place-items: center;
  backdrop-filter: brightness(50%);
}
.assessment-progress {
  height: 12px;
}
.assessment-progress .progress-bar {
  background-color: var(--pink) !important;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
