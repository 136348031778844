.treatment-steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

@media (min-width:992px) {
    .cancer-treatment-card-h {
        min-height: 435px;
    }

}

@media (min-width:992px) and (max-width:1199px) {
    .cancer-treatment-card .col:nth-child(4) .card-1 div:nth-child(1) {
        min-height: 68px;
    }

    .cancer-treatment-card .col:nth-child(5) .card-1 div:nth-child(1) {
        min-height: 68px;
    }

    .cancer-treatment-card .col:nth-child(6) .card-1 div:nth-child(1) {
        min-height: 68px;
    }
}

@media (max-width:991px) {
    .treatment-steps-container {
        flex-direction: column;
        gap: 24px;
    }

    .treatment-steps-container .steps-arrow {
        transform: rotate(90deg);
    }
}