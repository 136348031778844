.developing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.developing-card img {
  border-radius: 20px;
  width: 90%;
}

@media (min-width: 1400px) {
  .developing-p-width {
    width: 91%;
  }
}

@media (min-width: 1600px) {
  .developing-p-width {
    width: 78%;
  }
}

@media (min-width: 1800px) {
  .developing-p-width {
    width: 71%;
  }
}

@media (max-width: 991px) {
  .developing-card img {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .developing-card img {
    width: 80%;
  }
}
