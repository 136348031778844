.solutions-sec {
  background-color: #f8f8f8;
}

.solutions-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solutions-card img {
  width: 90%;
  margin-top: -12%;
}

.border-b-pink {
  display: inline-block;
  width: 75px;
  border-bottom: 2px solid var(--pink);
}

.border-b-green {
  display: inline-block;
  width: 75px;
  border-bottom: 2px solid var(--text-green);
}

@media (min-width: 768px) and (max-width: 991px) {
  .solutions-card img {
    width: 50%;
    margin-top: -7%;
  }
}

@media (min-width: 992px) {
  .solutions-card > div:nth-child(1) {
    min-height: 71px;
  }
}

@media (min-width: 1200px) {
  .cancer-risk-p-width {
    width: 95%;
  }
}

@media (min-width: 1400px) {
  .solutions-card > div:nth-child(1) {
    min-height: 76px;
  }

  .cancer-risk-p-width {
    width: 80%;
  }
}

@media (min-width: 1600px) {
  .cancer-risk-p-width {
    width: 75%;
  }
}

@media (min-width: 1800px) {
  .solutions-card > div:nth-child(1) {
    min-height: unset;
  }

  .cancer-risk-p-width {
    width: 65%;
  }
}

/* @media (max-width:991px) {
    .solutions-card p {
        display: none;
    }
} */

@media (max-width: 991px) {
  .solutions-card img {
    width: 100%;
  }
}
