.library-container {
    max-width: 1200px;
    margin: auto;
    padding: 15px;
}

.heading-main {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5rem;
}

.responsive-break {
    display: none;
}

@media (max-width: 768px) {
    .responsive-break {
        display: inline;
    }
}

.my-library-categories .swiper-wrapper {
    justify-content: space-between;
}

.my-library-categories .swiper-slide {
    width: fit-content;
}

.my-library-categories-inner {
    white-space: nowrap;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    padding: 12px 12px 6px 12px;
    cursor: pointer;
    text-transform: uppercase;
    border-bottom: 2px solid var(--pink);

}

.my-library-categories-inner span {
    display: block;
    width: 55px;
    margin: 6px auto 0 auto;
}

.post-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 25px;
    padding: 20px 0;
    margin: 10px;
}

.post-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: box-shadow 0.3s;
    border-radius: 40px;

}

.post-card:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
}

.post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.post-info {
    padding: 20px 10px;
}
.category-tag{
    background: #f2f2f2;
    padding: 0px;
    margin-right: 60px;
    color: #E99675; 
    cursor: default;
    background-color: #FFEFE2; /* Approximate hex code for Tailwind's bg-orange-100 */
    border-radius: 9999px; /* Effectively 'rounded-full' */
    width: 170px; /* Equivalent to 'w-40' in Tailwind */
    height: 40px; /* Equivalent to 'h-10' in Tailwind */
    font-size: 14px; /* Small text, close to 'text-sm' */
    font-weight: 600; /* 'font-semibold' */
    text-transform:capitalize; 
    border: none;
}
.post-date{
    font-size: 0.9rem;
    width: 10vw;

}
.post-title {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    height: 8vh;
    margin-bottom: 2.2rem;
    letter-spacing: 0.025rem;
}

.author-info {
    height: 6vh;
    display: flex;
    align-items: center;
}

.author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
}

.author-name {
    font-size: 1rem;
    font-weight: 500;
}

@media (max-width: 1399px) {
    .my-library-categories-inner {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .my-library-categories-inner {
        font-size: 14px;
    }

    .post-grid-container {
        grid-template-columns: 1fr;
    }
}
